import { Component } from 'react'
import { Route, Redirect, matchPath } from 'react-router-dom'
import { withRouter } from 'react-router'
import { connect } from 'react-redux';


//用于判断用户是否登录
class AuthRoute extends Component {
    render() {
        const { children, path } = this.props;
        return (
            <Route
                path={path}
                render={ ({ location }) =>{
                    let pathname = location.pathname;
                    let {user, token} = this.props;

                    //表示没有登录，就跳转到登录界面
                    if(!token){
                        return <Redirect to={{ pathname: "/flush", state: { from: location } }} />
                    }

                    //是管理员登录必须匹配路径是 /admin
                    let admin = matchPath(pathname, {
                        path: '/admin',
                        exact: false,
                        strict: false
                    })
                    
                    if(admin && user.loginRole === 0){
                        return children;
                    }

                    //是专家登录必须匹配路径是 /expert
                    let expert = matchPath(pathname, {
                        path: '/expert',
                        exact: false,
                        strict: false
                    }) 

                    if(expert && user.loginRole === 1 ){
                        return children;
                    }

                    //是管理员登录必须匹配路径是 /company
                    let company = matchPath(pathname, {
                        path: '/company',
                        exact: false,
                        strict: false
                    })

                    if(company && user.loginRole === 2 ){
                        return children;
                    }

                    return "访问无效路径";
                }}
            />
        );
    }
}

export default connect(
    state => {
        return {
            user: state.user,
            token: state.token
        }
    }
)(withRouter(AuthRoute));