import {Component} from 'react';
import {Spin} from 'antd';


class AppLoading extends Component{
    render(){
        return (
            <Spin size="large" wrapperClassName="loading" tip="加载中...">&nbsp;</Spin>
        );
    }
}

export default AppLoading