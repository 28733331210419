import React, {Component} from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import { routes, authRoutes } from './pages/routes';
import AppLoading from './components/AppLoading';
import RouteWithSubRoutes from './components/RouteWithSubRoutes';
import AuthRoute from './components/AuthRoute';

class App extends Component{
    render(){
        return (
            <React.Suspense fallback={<AppLoading />}>
                <Router>
                    <Switch>
                        <AuthRoute 
                            path={[
                                '/admin', 
                                '/expert',
                                '/company'
                            ]}
                        >
                            {authRoutes.map((e, i) => <RouteWithSubRoutes key={i} {...e}/>)}
                        </AuthRoute>
                        {routes.map(e => <RouteWithSubRoutes {...e} />)}
                        <Redirect path="/*" to="/login" />
                        <Redirect path="" exact to="http://www.bluepara.cn/hsa"/>
                    </Switch>
                </Router>
            </React.Suspense>
        );
    }
}

export default App;