import {
    STORE_LOGIN_ITEMS_ACTION,
    STORE_INDUSTRY_ACTION,
    STORE_ADDRESS_ACTION,
} from '~/actions'

const initState = {
    token: '',
    user: {},
    users: [],
    pageSize: 10,
    authorities: {},

    industry: {
        loaded: false,
        level0: [],
        level1: [],
        level2: [],
        level3: [],
    },

    address: {
        loaded: false,
        province: [],
        city: [],
        county: []
    }
}

const appReducer = (state = initState, action) => {
    let {type} = action;
    
    switch(type){
        case STORE_LOGIN_ITEMS_ACTION:
            let {TOKEN, user, users, authorities} = action.payload;
            return {...state, token: TOKEN, user, users, authorities: authorities.reduce((p, c) => {p[c]=c; return p;}, {})};
        case STORE_INDUSTRY_ACTION:
            return {...state, industry: action.payload};
        case STORE_ADDRESS_ACTION:
            return {...state, address: action.payload};
        default:
            return state;
    }
}

export default appReducer;