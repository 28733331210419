import { lazy } from "react";
const NotFound = lazy(() => import('./NotFound'))
const Login = lazy(() => import('./Login'))
const Register = lazy(() => import('./Register'))
const RegisterType = lazy(() => import('./Register/Type'))
const RegisterCompany = lazy(() => import('./Register/Company'))
const RegisterExpert = lazy(() => import('./Register/Expert'))
const Flush = lazy(() => import('./Flush'))
const RetrievePassword = lazy(() => import('./Password'))
const CheckEmail = lazy(() => import('./Password/Retrieve/CheckEmail'))
const EditPassword = lazy(() => import('./Password/Retrieve/EditPassword'))
const Index = lazy(() => import('./Index'))
// const About = lazy(() => import('./Index/About'))
const Declare = lazy(() => import('./Index/Declare'))
const Contact = lazy(() => import('./Index/Contact'))
const Solution = lazy(() => import('./Index/Solution'))
// const News = lazy(() => import('./Index/News'))
// const New = lazy(() => import('./Index/New'))
const Document = lazy(() => import('./Index/Document'))
const KpCert = lazy(() => import("./KpCert"));
const OnlyOffice = lazy(() => import("./Office"));
const OfficeShare = lazy(() => import("./Admin/Word/Share/EnterRoom"));

export const routes = [
    { key: 'new', path: '/kp/cert', component: KpCert },
    // { key: 'new', path: '/new', component: New },
    // { key: 'news', path: '/news', component: News },
    { key: 'solution', path: '/solution', component: Solution },
    { key: 'declare', path: '/declare', component: Declare },
    { key: 'contact', path: '/contact', component: Contact },
    // { key: 'about', path: '/about', component: About },
    { key: 'flush', path: '/flush', component: Flush },
    { key: 'login', path: '/login', component: Login },
    { key: 'doc', path: '/document', component: Document },
    { key: 'office_share', path: '/office/share', component: OfficeShare },
    { key: 'office', path: '/office', component: OnlyOffice },

    { 
        key: 'password', 
        path: '/password', 
        component: RetrievePassword, 
        routes:[
            {
                key: 'edit-password',
                path: '/password/edit',
                component: EditPassword,
            },
            {
                key: 'password',
                path: '/password',
                component: CheckEmail,
            }
        ],
    },
    
    {
        key: 'register',
        path: '/register',
        component: Register,
        routes: [
            {
                key: 'reg_cpy',
                path: '/register/company',
                component: RegisterCompany,
            },

            {
                key: 'reg_exp',
                path: '/register/expert',
                component: RegisterExpert,
            },

            {
                key: 'reg_exp',
                path: '/',
                component: RegisterType,
            }
        ]
    },
    { key: '404', path: '/404', component: NotFound },
    { key: 'index', path: '', component: Index },
]


const Admin = lazy(() => import('./Admin'))
const AdminDashboard = lazy(() => import('./Admin/Dashboard'))

const AdminExpert = lazy(() => import('./Admin/Expert'))
const AdminExpertList = lazy(() => import('./Admin/Expert/Expert'))
const AdminExpertCreate = lazy(() => import('./Admin/Expert/Expert/Create'))
const AdminExpertInfo = lazy(() => import('./Admin/Expert/Expert/EInfo'))
const AdminExpertEdit = lazy(() => import('./Admin/Expert/Expert/Edit'))
const AdminExpertAssess = lazy(() => import('./Admin/Expert/Assess'))
const AdminExpertAssessCreate = lazy(() => import('./Admin/Expert/Assess/Create'))
const AdminExpertAvatar = lazy(() => import('./Admin/Expert/ExpertAvatar'))


const AdminExpertShireBook = lazy(() => import('./Admin/Expert/ShireBook'))

const AdminResult = lazy(() => import('./Admin/Result'));
const AdminResultType = lazy(() => import('./Admin/Result/Type'));
const AdminResultTypeCreate = lazy(() => import('./Admin/Result/Type/Create'));
const AdminResultTypeShow = lazy(() => import('./Admin/Result/Type/Show'));
const AdminResultTypeEdit = lazy(() => import('./Admin/Result/Type/Edit'));
const AdminResultCertList = lazy(() => import('./Admin/Result/Cert'))

const AdminResultList = lazy(() => import('./Admin/Result/List'));
const AdminResultListCreate = lazy(() => import('./Admin/Result/List/Create'));
const AdminResultListShow = lazy(() => import('./Admin/Result/List/Show'));
const AdminResultListEdit = lazy(() => import('./Admin/Result/List/Edit'));

const AdminResultTask = lazy(() => import('./Admin/Result/ResultTask'));
const AdminResultTaskHandle = lazy(() => import('./Admin/Result/Task/Handle'));

const AdminPayment = lazy(() => import('./Admin/Payment'));
const AdminPaymentList = lazy(() => import('./Admin/Payment/List'));

const AdminCtse = lazy(() => import('./Admin/Ctse'));
const AdminCtseType = lazy(() => import('./Admin/Ctse/Type'));


const AdminEnterprise = lazy(() => import('./Admin/Enterprise'));
const AdminEnterpriseIndustry = lazy(() => import('./Admin/Enterprise/Industry'));
const AdminEnterpriseEvaluation = lazy(() => import('./Admin/Enterprise/Evaluation'));
const AdminEnterpriseOrg = lazy(() => import('./Admin/Enterprise/Org'));
const AdminCCPType = lazy(() => import('./Admin/Enterprise/CCPType'));
const AdminECType = lazy(() => import('./Admin/Enterprise/ECType'));
const AdminIPRType = lazy(() => import('./Admin/Enterprise/IPRType'));
const AdminQfRewardType = lazy(() => import('./Admin/Enterprise/QfRewardType'));
const AdminStandardType = lazy(() => import('./Admin/Enterprise/StandardType'));
const AdminCrmAuth= lazy(() => import('./Admin/Enterprise/CrmAuth'));

const Base = lazy(() => import('./Admin/Base'))

const AdminSolutionsList = lazy(() => import('./Admin/Base/Solutions'))
const AdminSolutionsRoute = lazy(() => import('./Admin/Base/Solutions/Route'))

const Expert = lazy(() => import('./Expert'));

const ExpertAssess = lazy(() => import('./Expert/Assess'));
const ExpertAssessList = lazy(() => import('./Expert/Assess/List'));
const ExpertAssessHandle = lazy(() => import('./Expert/Assess/Handle'));

const ExpertCenter = lazy(() => import('./Expert/Center'));
const ExpertCenterShireBook = lazy(() => import('./Expert/Center/ShireBook'))
const ExpertCenterSettle = lazy(() => import('./Expert/Center/Settle'))
const ExpertCenterHistoryResult = lazy(() => import('./Expert/Center/HistoryResult'))
const ExpertCenterHistoryResultShow = lazy(() => import('./Expert/Center/HistoryResult/Show'))
const ExpertCenterSignature = lazy(() => import('./Expert/Center/Signature'));


const ExpertHisotry = lazy(() => import('./Expert/History'));
const ExpertHisotryAssess = lazy(() => import('./Expert/History/Assess'));

const ExpertInfo = lazy(() => import('./Expert/Info'));
const ExpertBasicInfo = lazy(() => import('./Expert/Info/Basic'));
const ExpertAcademicInfo = lazy(() => import('./Expert/Info/Academic'));
const ExpertPartJobInfo = lazy(() => import('./Expert/Info/PartTimeJob'));
const ExpertLangInfo = lazy(() => import('./Expert/Info/Lang'));
const ExpertTitleInfo = lazy(() => import('./Expert/Info/Title'));
const ExpertBankAccountInfo = lazy(() => import('./Expert/Info/BankAccount'));

const ExpertSetting = lazy(() => import('./Expert/Settings'));
const ExpertSettingResult = lazy(() => import('./Expert/Settings/Result'));
const ExpertIndex = lazy(() => import('./Expert/Index/index'));

const Company = lazy(() => import('./Company'));
const CompanyDiagnosis = lazy(() => import('./Company/Diagnosis'));


const Hsa = lazy(() => import("./Admin/Hsa"));
const Carousel = lazy(() => import("./Admin/Hsa/Carousel"));
const HsaNews = lazy(() => import("./Admin/Hsa/News"));
const HsaDownload = lazy(() => import("./Admin/Hsa/Download"));
const HsaCourseware = lazy(() => import("./Admin/Hsa/Courseware"));
const HsaOrg = lazy(() => import("./Admin/Hsa/Org"));
const HsaConsult = lazy(() => import("./Admin/Hsa/Consult"));

const ExpertBaseMajorZone = lazy(() => import('./Admin/Base/MajorZone'));
const ExpertBaseHoner = lazy(() => import('./Admin/Base/Honer'));
const ExpertBaseLandscape = lazy(() => import('./Admin/Base/Landscape'));
const ExpertBaseMajor = lazy(() => import('./Admin/Base/Major'));
const ExpertBaseUniversity = lazy(() => import('./Admin/Base/University'));
const ExpertBasePosition = lazy(() => import('./Admin/Base/Position'));
const ExpertBaseIndustry = lazy(() => import('./Admin/Base/Industry'));
const ExpertBaseOrgType = lazy(() => import('./Admin/Base/OrgType'));

const AdminCRM = lazy(() => import('./Admin/CRM/index'))
const AdminCRMOpp = lazy(() => import('./Admin/CRM/Opp'));
const AdminCRMOppSource = lazy(() => import('./Admin/CRM/OppSource'));
const AdminCRMCustomer = lazy(() => import('./Admin/CRM/Customer'));
const AdminCRMMarketer = lazy(() => import('./Admin/CRM/Marketer/index'));
const AdminCRMContract = lazy(() => import('./Admin/CRM/Contract'));
const AdminCRMProject = lazy(() => import('./Admin/CRM/Project'));
const AdminCRMProjectType = lazy(() => import('./Admin/CRM/ProjectType'));
const AdminCRMProjectRole = lazy(() => import('./Admin/CRM/ProjectRole'));
const AdminCRMOwnerDepart = lazy(() => import('./Admin/CRM/OwnerDepart'));
const AdminCRMBusinessStatus = lazy(() => import('./Admin/CRM/BusinessStatus'));
const AdminCRMProjectStatus = lazy(() => import('./Admin/CRM/ProjectStatus'));
const AdminCRMPartyB = lazy(() => import('./Admin/CRM/PartyB'));
const AdminCRMContractType = lazy(() => import('./Admin/CRM/ContractType'));

const AdminCRMOppView = lazy(() => import('./Admin/CRM/OppView'));
const AdminCRMProjectView = lazy(() => import('./Admin/CRM/ProjectView'));
const AdminCRMContractView = lazy(() => import('./Admin/CRM/ContractView'));

const Policy = lazy(() => import('./Admin/Policy'));
const PolicyDiagnosis = lazy(() => import('./Admin/Policy/Diagnosis'));
const PolicyProject = lazy(() => import('./Admin/Policy/PolicyProject'));
const PolicyAbility = lazy(() => import('./Admin/Policy/Ability'));

const AdminUser = lazy(() => import('./Admin/User'))
const AdminUserList = lazy(() => import('./Admin/User/UserList'))

const AdminShia = lazy(() => import('./Admin/Shia'))
const ShiaMenu = lazy(() => import('./Admin/Shia/Menu/index'))
const ShiaArticle = lazy(() => import('./Admin/Shia/Article/index'))

const MapIndex = lazy(() => import('./Admin/Map'))
const MapConfig = lazy(() => import('./Admin/Map/Config'))


const Rbac = lazy(() => import('./Admin/Rbac'));
const RbacRole = lazy(() => import('./Admin/Rbac/Role'));
const RbacAuthority = lazy(() => import('./Admin/Rbac/Authority'));
const RbacUser = lazy(() => import('./Admin/Rbac/User'));

const Message = lazy(() => import('./Admin/Message'))
const MessageTemplate = lazy(() => import('./Admin/Message/Template'))
const MessageRecord = lazy(() => import('./Admin/Message/Record'))
const MessageVerifyCode = lazy(() => import('./Admin/Message/VerifyCode'))
const MessageInternal = lazy(() => import('./Admin/Message/Internal'))

const Word = lazy(() => import('./Admin/Word'))
const WordManager = lazy(() => import('./Admin/Word/Manager'))
const WordShare = lazy(() => import('./Admin/Word/Share'))
const WordGroup = lazy(() => import('./Admin/Word/DocumentGroup'))
const WordUser = lazy(() => import('./Admin/Word/DocumentUser'))
const WordPermission = lazy(() => import('./Admin/Word/DocumentPermission'))

const Files = lazy(() => import('./Admin/Files'))
const FilesBackup = lazy(() => import('./Admin/Files/Backup'))

export const authRoutes = [
    {
        path: '/company',
        component: Company,
        routes: [
            {
                path: '/company/diagnosis',
                component: CompanyDiagnosis
            }
        ]
    },
    {
        path: '/expert',
        component: Expert,
        routes: [
            {
                path: '/expert/settings',
                component: ExpertSetting,

                routes:[
                    {
                        path: '/expert/settings',
                        component: ExpertSettingResult,
                    }
                ]
            },
            {
                path: '/expert/assess',
                component: ExpertAssess,

                routes:[
                    {
                        path: '/expert/assess/handle',
                        component: ExpertAssessHandle,
                    },
                    {
                        path: '/expert/assess',
                        component: ExpertAssessList,
                    },
                ]
            },

            {
                path: '/expert/info',
                component: ExpertInfo,
                routes: [
                    {
                        path:'/expert/info/basic',
                        component: ExpertBasicInfo
                    },
                    {
                        path:'/expert/info/academic',
                        component: ExpertAcademicInfo
                    },
                    {
                        path:'/expert/info/partjob',
                        component: ExpertPartJobInfo
                    },
                    {
                        path:'/expert/info/bankaccount',
                        component: ExpertBankAccountInfo
                    },
                    {
                        path:'/expert/info/lang',
                        component: ExpertLangInfo
                    },
                    {
                        path:'/expert/info/title',
                        component: ExpertTitleInfo
                    },
                    // {
                    //     path:'/expert/info/bid',
                    //     // component: BidExp
                    // },
                ]
            },
            {
                path: '/expert/history',
                component: ExpertHisotry,
                routes: [
                    {
                        path: '/expert/history/assess',
                        component: ExpertHisotryAssess
                    }
                ]
            },
            {
                path: '/expert/center',
                component: ExpertCenter,
                routes: [
                    {
                        path: '/expert/center/signature',
                        component: ExpertCenterSignature
                    },
                    {
                        path: '/expert/center/shirebook',
                        component: ExpertCenterShireBook
                    },
                    {
                        path: '/expert/center/settle',
                        component: ExpertCenterSettle
                    },
                    {
                        path: '/expert/center/history/show',
                        component: ExpertCenterHistoryResultShow
                    },
                    {
                        path: '/expert/center/history',
                        component: ExpertCenterHistoryResult
                    },
                ]
            },

            {
                path: '',
                component: ExpertIndex,
            },
        ]
    },
    {
        path: '/admin',
        component: Admin,
        routes: [
            {
                path: '/admin/files',
                component: Files,
                routes:[
                    {
                        path: '/admin/files/backup',
                        component: FilesBackup,
                    },
                ]
            },
            {
                path: '/admin/word',
                component: Word,
                routes:[
                    {
                        path: '/admin/word/manager',
                        component: WordManager,
                    },
                    {
                        path: '/admin/word/share',
                        component: WordShare,
                    },
                    {
                        path: '/admin/word/permission',
                        component: WordPermission,
                    },
                    {
                        path: '/admin/word/group',
                        component: WordGroup,
                    },
                    {
                        path: '/admin/word/user',
                        component: WordUser,
                    },
                ]
            },
            {
                path: '/admin/message',
                component: Message,
                routes: [
                    {
                        path: '/admin/message/verify',
                        component: MessageVerifyCode,
                    },
                    {
                        path: '/admin/message/internal',
                        component: MessageInternal,
                    },
                    {
                        path: '/admin/message/record',
                        component: MessageRecord,
                    },
                    {
                        path: '/admin/message/template',
                        component: MessageTemplate,
                    }
                ]
            },
            {
                path: '/admin/rbac',
                component: Rbac,
                routes: [
                    {
                        path: '/admin/rbac/user',
                        component: RbacUser,
                    },
                    {
                        path: '/admin/rbac/role',
                        component: RbacRole,
                    },
                    {
                        path: '/admin/rbac/authority',
                        component: RbacAuthority,
                    }
                ]
            },
            {
                path: '/admin/map',
                component: MapIndex,

                routes: [
                    {
                        path: '/admin/map/config',
                        component: MapConfig,
                    },
                    {
                        path: '/admin/map',
                        component: MapConfig,
                    }
                ]
            },
            {
                path: '/admin/shia',
                component: AdminShia,

                routes: [
                    {
                        path: '/admin/shia/menu',
                        component: ShiaMenu,
                    },
                    {
                        path: '/admin/shia/article',
                        component: ShiaArticle,
                    },
                    {
                        path: '/admin/shia',
                        component: ShiaMenu,
                    }
                ]
            },
            {
                path: '/admin/user',
                component: AdminUser,

                routes: [
                    {
                        path: '/admin/user',
                        component: AdminUserList,
                    }
                ]
            },
            {
                path: '/admin/policy',
                component: Policy,

                routes: [
                    {
                        path: '/admin/policy/ability',
                        component: PolicyAbility,
                    },
                    {
                        path: '/admin/policy/project',
                        component: PolicyProject,
                    },
                    {
                        path: '/admin/policy/diagnosis',
                        component: PolicyDiagnosis,
                    },
                ]
            },
            {
                path: '/admin/crm',
                component: AdminCRM,

                routes: [
                    {
                        path: '/admin/crm/contract_type',
                        component: AdminCRMContractType,
                    },
                    {
                        path: '/admin/crm/party_b',
                        component: AdminCRMPartyB,
                    },
                    {
                        path: '/admin/crm/business_status',
                        component: AdminCRMBusinessStatus,
                    },
                    {
                        path: '/admin/crm/project_status',
                        component: AdminCRMProjectStatus,
                    },
                    {
                        path: '/admin/crm/owner_depart',
                        component: AdminCRMOwnerDepart,
                    },
                    {
                        path: '/admin/crm/project/role',
                        component: AdminCRMProjectRole,
                    },
                    {
                        path: '/admin/crm/project/type',
                        component: AdminCRMProjectType,
                    },
                    {
                        path: '/admin/crm/project',
                        component: AdminCRMProject,
                    },
                    {
                        path: '/admin/crm/contract',
                        component: AdminCRMContract,
                    },
                    {
                        path: '/admin/crm/marketer',
                        component: AdminCRMMarketer,
                    },
                    {
                        path: '/admin/crm/customer',
                        component: AdminCRMCustomer,
                    },
                    {
                        path: '/admin/crm/opp/source',
                        component: AdminCRMOppSource,
                    },
                    {
                        path: '/admin/crm/opp/:state',
                        component: AdminCRMOpp,
                    },
                    {
                        path: '/admin/crm/opp',
                        component: AdminCRMOpp,
                    },
                    {
                        path: '/admin/crm/contract_view',
                        component: AdminCRMContractView,
                    },
                    {
                        path: '/admin/crm/opportunity_view',
                        component: AdminCRMOppView,
                    },
                    {
                        path: '/admin/crm/project_view',
                        component: AdminCRMProjectView,
                    },
                ]
            },
            {
                path: '/admin/enterprise',
                component: AdminEnterprise,
                routes: [
                    {
                        path: '/admin/enterprise/evaluation',
                        component: AdminEnterpriseEvaluation,
                    },
                    {
                        path: '/admin/enterprise/industry',
                        component: AdminEnterpriseIndustry,
                    },
                    {
                         path: '/admin/enterprise/crmAuth',
                         component: AdminCrmAuth,
                    },

                    {
                        path: '/admin/enterprise/CCPType',
                        component: AdminCCPType,
                    },
                    {
                        path: '/admin/enterprise/ECType',
                        component: AdminECType,
                    },
                    {
                        path: '/admin/enterprise/IPRType',
                        component: AdminIPRType,
                    },
                    {
                        path: '/admin/enterprise/qfRewardType',
                        component: AdminQfRewardType,
                    },
                    {
                        path: '/admin/enterprise/standardType',
                        component: AdminStandardType,
                    },
                    {
                        path: '/admin/enterprise/org',
                        component: AdminEnterpriseOrg,
                    },
                    {
                        path: '/admin/enterprise',
                        component: AdminEnterpriseOrg,
                    }
                ]
            },
            {
                path: '/admin/payment',
                component: AdminPayment,

                routes: [
                    {
                        path: '/admin/payment',
                        component: AdminPaymentList,
                    }
                ]
            },
            {
                path: '/admin/ctse',
                component: AdminCtse,
                routes: [
                    {
                        path: '/admin/ctse/type',
                        component: AdminCtseType
                    }
                ]
            },
            {
                path: '/admin/result',
                component: AdminResult,
                routes: [
                    {
                        path: '/admin/result/cert',
                        component: AdminResultCertList
                    },
                    {
                        path: '/admin/result/task/handle',
                        component: AdminResultTaskHandle
                    },
                    {
                        path: '/admin/result/list/edit',
                        component: AdminResultListEdit
                    },
                    {
                        path: '/admin/result/list/show',
                        component: AdminResultListShow
                    },
                    {
                        path: '/admin/result/list/create',
                        component: AdminResultListCreate
                    },
                    {
                        path: '/admin/result/type/edit',
                        component: AdminResultTypeEdit
                    },
                    {
                        path: '/admin/result/type/show',
                        component: AdminResultTypeShow
                    },
                    {
                        path: '/admin/result/type/create',
                        component: AdminResultTypeCreate
                    },
                    {
                        path: '/admin/result/list',
                        component: AdminResultList,
                    },
                    {
                        path: '/admin/result/type',
                        component: AdminResultType,
                    },
                    {
                        path: '/admin/result/task',
                        component: AdminResultTask,
                    },
                    {
                        path: '/admin/result',
                        component: AdminResultList,
                    }
                ]
            },
            {
                path: '/admin/hsa',
                component: Hsa,
                routes: [
                    {
                        path: '/admin/hsa/consult',
                        component: HsaConsult
                    },
                    {
                        path: '/admin/hsa/org',
                        component: HsaOrg
                    },
                    {
                        path: '/admin/hsa/courseware',
                        component: HsaCourseware
                    },
                    {
                        path: '/admin/hsa/download',
                        component: HsaDownload
                    },
                    {
                        path: '/admin/hsa/news',
                        component: HsaNews
                    },
                    {
                        path: '/admin/hsa/carousel',
                        component: Carousel
                    },
                    {
                        path: '/admin/hsa',
                        component: Carousel
                    }
                ]
            },
            {
                path: '/admin/base',
                component: Base,
                routes: [
                    {
                        path: '/admin/base/orgType',
                        component: ExpertBaseOrgType,
                    },
                    {
                        path: '/admin/base/industry',
                        component: ExpertBaseIndustry,
                    },
                    {
                        path: '/admin/base/position',
                        component: ExpertBasePosition,
                    },
                    {
                        path: '/admin/base/major',
                        component: ExpertBaseMajor,
                    },
                    {
                        path: '/admin/base/university',
                        component: ExpertBaseUniversity,
                    },
                    {
                        path: '/admin/base/landscape',
                        component: ExpertBaseLandscape,
                    },
                    {
                        path: '/admin/base/honer',
                        component: ExpertBaseHoner,
                    },
                    {
                        path: '/admin/base/zone',
                        component: ExpertBaseMajorZone,
                    },
                    {
                        path: '/admin/base/solutions',
                        component: AdminSolutionsRoute,
                        routes: [
                            {
                                path: '/admin/base/solutions/list',
                                component: AdminSolutionsList,
                            }
                        ]
                    }
                ]
            },

            {
                path: '/admin/expert',
                component: AdminExpert,
                routes: [
                    {
                        path: '/admin/expert/avatar',
                        component: AdminExpertAvatar,
                    },
                    {
                        path: '/admin/expert/shirebook',
                        component: AdminExpertShireBook,
                    },
                    {
                        path: '/admin/expert/assess/create',
                        component: AdminExpertAssessCreate,
                    },
                    {
                        path: '/admin/expert/assess',
                        component: AdminExpertAssess,
                    },
                    {
                        path: '/admin/expert/info',
                        component: AdminExpertInfo,
                    },
                    {
                        path: '/admin/expert/edit',
                        component: AdminExpertEdit,
                    },
                    {
                        path: '/admin/expert/create',
                        component: AdminExpertCreate,
                    },
                    {
                        path: '',
                        component: AdminExpertList,
                    },
                    
                ]
            },
            {
                path: '',
                component: AdminDashboard,
            },
        ]
    }
]
